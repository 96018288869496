.menu_item {
    border-radius: 10px;
    padding: 15px 5px;
    color: var(--chakra-colors-blue-500);
}

.chakra-icon, .menu_item:hover {
    background: var(--chakra-colors-pink-50);
    color: var(--chakra-colors-pink-500);
}
